import React from 'react';
import { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import axios from 'axios';
const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

export default function TestPage({ params }) {
  const testId = params[`testId`];
  const [test, setTest] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState('IAENG51');
  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    switch (testId) {
      case 'QUIZ1':
        setTest(
          'https://docs.google.com/forms/d/e/1FAIpQLSeeog3B-uizgiEAvbn3cXQwYJTWgrZnJomRgfXZw23Az4kf1A/viewform'
        );
        break;
      case 'feedback':
        setShowFeedback(true);
        break;
    }
  }, []);

  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [submitted, setSubitted] = useState(false);

  const onSubmit = async () => {
    if (!message || !selectedCourse) {
      return alert('Please select a course and add a message.');
    }
    setSubitted(true);
    await axios.post(`${API_URL}/feedback`, {
      name,
      course: selectedCourse,
      message,
    });
    return;
  };

  const feedbackForm = () => {
    return (
      <>
        <h1 style={{ marginBottom: 10 }}>Select Course:</h1>
        <select
          onChange={(e) => setSelectedCourse(e.target.value)}
          style={{ marginBottom: 10 }}
        >
          <option value="IAENG51">1. Ethics for Iowa Engineers</option>
          <option value="IAENG52">
            2. Blockchain in Engineering – An Emerging Trend
          </option>
        </select>
        <label>
          Name (Optional)
          <input
            type="text"
            name="name"
            id="name"
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <textarea
          name="demo-message"
          id="demo-message"
          placeholder="Enter your message"
          onChange={(e) => setMessage(e.target.value)}
          rows="6"
        ></textarea>
        <button onClick={onSubmit} style={{ marginTop: 20 }}>
          Submit
        </button>
      </>
    );
  };

  const feedbackFeed = () => {
    const feedback = {
      IAENG51: [
        'The content was well written and helped expand my knowledge on this subject - Edward',
        'I enjoyed reading the course at my own pace from the comfort of my home - Andrew',
        'Advance made it extremely easy for me to satisfy my CE requirements - Joe',
      ],
      IAENG52: [
        'This course highlights the relationship between blockchain and engineering. I had very little knowledge of this subject, but I found it to be an extremely interesting read - Frank',
        'I thoroughly enjoyed this course. I look forward to reading and learning more about this in the future!” – Karen',
        'Blockchain seems like it will have a profound impact in the engineering sector for years to come – Charles',
      ],
    };

    const FeedBacks = feedback[selectedCourse].map((f) => (
      <p>
        <i>{f}</i>
      </p>
    ));
    return (
      <>
        <h1 style={{ marginTop: 10 }}>What your peers have said</h1>
        {FeedBacks}
      </>
    );
  };

  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) return null;
  const height = window.innerHeight;

  return (
    <Layout fullMenu style={{ overflow: 'hidden' }} logoUrl="/IA/engineering">
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center',
          marginTop: 120,
          padding: 20,
          backgroundColor: '#2e3842',
          width: '100%',
          color: 'white',
          overflow: 'hidden',
          paddingBottom: 90,
        }}
      >
        {submitted ? (
          <div style={{ textAlign: 'center' }}>
            <h2>Thank you for your feedback!</h2>
          </div>
        ) : (
          <>
            <h2 style={{ textAlign: 'center' }}>Course Feedback</h2>
            {feedbackForm()}
          </>
        )}
      </section>
      <section id="three" className="wrapper style3 special">
        <div className="inner">{feedbackFeed()}</div>
      </section>
    </Layout>
  );
  // When visiting a route like `image/hello/world`,
  // the value of `param` is `hello/world`.
}
